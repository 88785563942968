<template>
  <div class="file-detail text-left">
    <p class="font-bold text-2xl mb-4">{{ titleDetails }} Detail</p>
    <div v-if="!isEditMode">
      <div class="flex items-start mb-4 px-1">
        <label class="w-32 text-sm mb-0 font-medium flex-shrink-0">Title</label>
        <p class="text-sm break-all">{{ file.title }}</p>
      </div>
      <div class="flex items-start mb-4 px-1">
        <label class="w-32 text-sm mb-0 font-medium flex-shrink-0">Caption</label>
        <p class="text-sm">
          {{ file.caption }}
        </p>
      </div>
      <div class="flex items-start mb-4 px-1">
        <label class="w-32 text-sm mb-0 font-medium flex-shrink-0">Description</label>
        <p class="text-sm">
          {{ file.description }}
        </p>
      </div>
    </div>
    <div v-else>
      <div class="mb-4 px-1">
        <TextField type="text" label="Title" borderEnabled v-model="title" />
        <p class="text-right text-sm pr-1 pt-1">{{ folderNameLength }} / 200</p>
      </div>
      <div class="mb-4 px-1">
        <TextField type="text" label="Caption" borderEnabled v-model="caption" />
      </div>
      <div class="mb-4 px-1">
        <TextArea label="Description" borderEnabled v-model="description" />
      </div>
    </div>
    <div class="flex items-start mb-4 px-1">
      <label class="w-32 text-sm mb-0 font-medium flex-shrink-0">Type</label>
      <p class="text-sm" v-if="file.mimeType === 'FOLDER'">Folder</p>
      <p class="text-sm" v-else>
        {{ isVideo(file.mimeType) ? 'Video' : 'Document' }}
        <span class="inline-block text-neutral-500">({{ file.mimeType }}) </span>
      </p>
    </div>
    <!-- <div class="flex items-start mb-4 px-1">
      <label class="w-32 text-sm mb-0 font-medium flex-shrink-0">Size</label>
      <p class="text-sm">-</p>
    </div> -->
    <div class="flex items-start mb-4 px-1" v-if="isVideo(file.mimeType)">
      <label class="w-32 text-sm mb-0 font-medium flex-shrink-0">Duration</label>
      <p class="text-sm">
        {{ getDuration(file.durationInSeconds) }}
      </p>
    </div>
    <div class="flex items-start mb-4 px-1">
      <label class="w-32 text-sm mb-0 font-medium flex-shrink-0">Uploaded</label>
      <p class="text-sm">
        {{ formatDate(file.createdDate) }}
      </p>
    </div>
    <div class="text-center">
      <div class="inline-block w-48">
        <Button v-if="isEditMode" buttonText="Save" size="full" @action="save()" />
        <Button buttonText="Edit" size="full" @action="toggleEdit()" v-else />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import moment from 'moment'
export default {
  components: {
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    TextArea: () => import(/* webpackChunkName: "TextArea" */ '@/components/Form/TextArea'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button')
  },
  props: ['file'],
  data() {
    return {
      isEditMode: false,
      title: null,
      caption: null,
      description: null
    }
  },
  computed: {
    clientId() {
      return localStorage.getItem('client')
    },
    titleDetails() {
      return this.file.mimeType === 'FOLDER' ? 'Folder' : 'File'
    },
    folderNameLength() {
      return this.title.length
    }
  },
  watch: {
    isEditMode(value) {
      if (value) {
        this.title = JSON.parse(JSON.stringify(this.file.title))
        if (this.file.caption) this.caption = JSON.parse(JSON.stringify(this.file.caption))
        if (this.file.description) this.description = JSON.parse(JSON.stringify(this.file.description))
      }
    },
    title(val) {
      let removeSlash = val.replace(/[/]/g, '')
      this.title = removeSlash
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('gallery', ['UPDATE_FILE']),
    formatDate(date) {
      return moment(date).local().format('DD MMM YYYY HH:mm')
    },
    isVideo(type) {
      return type.includes('video')
    },
    getDuration(totalSeconds) {
      let hours = Math.floor(totalSeconds / 3600)
      totalSeconds %= 3600
      let minutes = Math.floor(totalSeconds / 60)
      let seconds = totalSeconds % 60
      minutes = String(minutes).padStart(2, '0')
      hours = String(hours).padStart(2, '0')
      seconds = String(seconds).padStart(2, '0')
      return hours + ':' + minutes + ':' + seconds
    },
    toggleEdit() {
      this.isEditMode = !this.isEditMode
    },
    save() {
      this.showLoading()
      this.UPDATE_FILE({
        id: this.file.id,
        payloads: {
          customerId: this.clientId,
          title: this.title,
          caption: this.caption,
          description: this.description
        }
      }).then(() => {
        this.hideLoading()
        this.$emit('close')
      })
    }
  }
}
</script>
