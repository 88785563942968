var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "file-detail text-left"
  }, [_c('p', {
    staticClass: "font-bold text-2xl mb-4"
  }, [_vm._v(_vm._s(_vm.titleDetails) + " Detail")]), !_vm.isEditMode ? _c('div', [_c('div', {
    staticClass: "flex items-start mb-4 px-1"
  }, [_c('label', {
    staticClass: "w-32 text-sm mb-0 font-medium flex-shrink-0"
  }, [_vm._v("Title")]), _c('p', {
    staticClass: "text-sm break-all"
  }, [_vm._v(_vm._s(_vm.file.title))])]), _c('div', {
    staticClass: "flex items-start mb-4 px-1"
  }, [_c('label', {
    staticClass: "w-32 text-sm mb-0 font-medium flex-shrink-0"
  }, [_vm._v("Caption")]), _c('p', {
    staticClass: "text-sm"
  }, [_vm._v(" " + _vm._s(_vm.file.caption) + " ")])]), _c('div', {
    staticClass: "flex items-start mb-4 px-1"
  }, [_c('label', {
    staticClass: "w-32 text-sm mb-0 font-medium flex-shrink-0"
  }, [_vm._v("Description")]), _c('p', {
    staticClass: "text-sm"
  }, [_vm._v(" " + _vm._s(_vm.file.description) + " ")])])]) : _c('div', [_c('div', {
    staticClass: "mb-4 px-1"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Title",
      "borderEnabled": ""
    },
    model: {
      value: _vm.title,
      callback: function callback($$v) {
        _vm.title = $$v;
      },
      expression: "title"
    }
  }), _c('p', {
    staticClass: "text-right text-sm pr-1 pt-1"
  }, [_vm._v(_vm._s(_vm.folderNameLength) + " / 200")])], 1), _c('div', {
    staticClass: "mb-4 px-1"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Caption",
      "borderEnabled": ""
    },
    model: {
      value: _vm.caption,
      callback: function callback($$v) {
        _vm.caption = $$v;
      },
      expression: "caption"
    }
  })], 1), _c('div', {
    staticClass: "mb-4 px-1"
  }, [_c('TextArea', {
    attrs: {
      "label": "Description",
      "borderEnabled": ""
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  })], 1)]), _c('div', {
    staticClass: "flex items-start mb-4 px-1"
  }, [_c('label', {
    staticClass: "w-32 text-sm mb-0 font-medium flex-shrink-0"
  }, [_vm._v("Type")]), _vm.file.mimeType === 'FOLDER' ? _c('p', {
    staticClass: "text-sm"
  }, [_vm._v("Folder")]) : _c('p', {
    staticClass: "text-sm"
  }, [_vm._v(" " + _vm._s(_vm.isVideo(_vm.file.mimeType) ? 'Video' : 'Document') + " "), _c('span', {
    staticClass: "inline-block text-neutral-500"
  }, [_vm._v("(" + _vm._s(_vm.file.mimeType) + ") ")])])]), _vm.isVideo(_vm.file.mimeType) ? _c('div', {
    staticClass: "flex items-start mb-4 px-1"
  }, [_c('label', {
    staticClass: "w-32 text-sm mb-0 font-medium flex-shrink-0"
  }, [_vm._v("Duration")]), _c('p', {
    staticClass: "text-sm"
  }, [_vm._v(" " + _vm._s(_vm.getDuration(_vm.file.durationInSeconds)) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "flex items-start mb-4 px-1"
  }, [_c('label', {
    staticClass: "w-32 text-sm mb-0 font-medium flex-shrink-0"
  }, [_vm._v("Uploaded")]), _c('p', {
    staticClass: "text-sm"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.file.createdDate)) + " ")])]), _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "inline-block w-48"
  }, [_vm.isEditMode ? _c('Button', {
    attrs: {
      "buttonText": "Save",
      "size": "full"
    },
    on: {
      "action": function action($event) {
        return _vm.save();
      }
    }
  }) : _c('Button', {
    attrs: {
      "buttonText": "Edit",
      "size": "full"
    },
    on: {
      "action": function action($event) {
        return _vm.toggleEdit();
      }
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }